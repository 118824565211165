export class CustomComponents {

    public static register(registry) {
        // IMPORT CUSTOM CLIENT COMPONENTS HERE
        registry.Top10Component = {
            loadChildren: () =>
                import('./top10/top10.module').then(m => m.Top10Module)
        };
        registry.CapacityComponent = {
            loadChildren: () =>
                import('./capacity/capacity.module').then(m => m.CapacityModule)
        };
    }
}
